<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <msg-bar />
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <!-- Brand logo-->
        <b-link class="brand-logo mt-5">
          <div
            v-if="logoLoading"
            class="spinner-border"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <b-img
            v-if="!logoLoading"
            class="dn-brand-logo"
            variant="light-company"
            :src="form.company_logo ? `${bucketUrl}${form.company_logo}`: defaultCompanyLogo"
          />
          <h2 class="brand-text text-primary ml-1 mt-1">
            {{ form.company_name }}
          </h2>
        </b-link>
        <!-- /Brand logo-->
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-start align-items-center mb-1">
            <h2
              class="font-weight-bold mr-1 mt-1"
            >
              Welcome to {{ form.company_name }}
            </h2>
            <img
              class="d-none d-md-flex"
              style="width:40px;height:40px;"
              src="@/assets/images/gifs/hand.gif"
            >
          </div>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="admin1@parallax.lk"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br>
                  <small
                    class="text-warning"
                    v-show="(tenant === 'demo' || tenant === 'mng') && sampleEmail"
                  >
                    Demo Email : {{ sampleEmail }}
                  </small>
                  <br>
                  <small
                    v-show="(tenant === 'demo' || tenant === 'mng') && samplePW"
                    class="text-warning"
                  >
                    Demo Password : {{ samplePW }}
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay
                :opacity="0.5"
                :show="loading"
                rounded="sm"
                :blur="'true'"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
                >
                  Sign in
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link
              :to="{name:'merchant-registration'}"
            >
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BImg, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl, defaultCompanyLogo, tenant } from '@/constants/config'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import {
  mapActions,
} from 'vuex'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import MsgBar from '../components/Notification/msg_banner.vue'

const BusinessRepository = RepositoryFactory.get('businessSettings')

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BForm,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    MsgBar,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      defaultCompanyLogo,
      bucketUrl,
      status: '',
      password: '',
      userEmail: '',
      form: {},
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      logoLoading: false,
      sampleEmail: process.env.VUE_APP_SAMPLE_EMAIL,
      samplePW: process.env.VUE_APP_SAMPLE_PW,
      tenant,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async created() {
    this.initDataFill()
  },
  mounted() {
    this.autoLogin()
    this.readDataforBusiness()
  },
  methods: {
    async readDataforBusiness() {
      this.logoLoading = true
      try {
        const { data } = (await BusinessRepository.getBusinessSettigs()).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.logoLoading = false
    },
    ...mapActions(['merchantLogin']),
    async validationForm() {
      if (await this.$refs.loginValidation.validate()) {
        // eslint-disable-next-line no-underscore-dangle
        this.__clearCache()
        this.onClickSubmit()
      }
    },
    autoLogin() {
      if (this.userEmail && this.password) {
        this.validationForm()
      }
    },
    async initDataFill() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      if (urlSearchParams.get('email') && urlSearchParams.get('password')) {
        this.userEmail = urlSearchParams.get('email')
        this.password = urlSearchParams.get('password')
      }
    },
    async onClickSubmit() {
      this.loading = true
      try {
        this.payload = {
          email: this.userEmail,
          password: this.password,
        }
        await this.merchantLogin(this.payload)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.dn-brand-logo {
  display: block;
  width: 45px;
  height: 45px;
  margin: auto;
}
</style>
